import React from "react";
import {Icon} from "@autopay.io/style";

type Status = "SUCCESS" | "ERROR";
interface PlateWithStatusProps{
    plateId: string;
    statusIcon?: Status
}
export const PlateWithStatus = (props: PlateWithStatusProps) => {
    if (props.statusIcon === "SUCCESS") {
        return <div className='vehicle-reg-container'>
            <div className={'padding_icon'}>
                <Icon icon={'check_green'}/>
                <span className={'vehicle-reg'}>{props.plateId}</span>
            </div>
        </div>;
    } else if (props.statusIcon === "ERROR") {
        return <div className='vehicle-reg-container'>
            <div className={'padding_icon'}>
                <Icon icon={'excl_red'}/>
                <span className={'vehicle-reg'}>{props.plateId}</span>
            </div>
        </div>;
    } else {
        return <div className='vehicle-reg-container plain-vehicle-reg-container'>
            <div className={'padding_icon plain-vehicle-reg-container'}>
                <span className={'vehicle-reg'}>{props.plateId}</span>
            </div>
        </div>;
    }
}

export default PlateWithStatus;