import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import Util from "../../components/util/util";
import Input from "@autopay.io/style/lib/components/Input/Input";
import Button from "@autopay.io/style/lib/components/Button/Button";
import {Translation} from "@autopay.io/translation";
import KeyboardComponent from '../../components/util/KeyboardComponent';
import Spinner from "@autopay.io/style/lib/components/Spinner/Spinner";
import Error from "./scenes/error";
import Success from "./scenes/success";
import {DeviceInformation, ErrorTypes, ValidationAndVehicleInformation} from './types';
import {config, constants} from "../../constants";
import {sendStatusCheckIn, getInformation, validateAndVehicleInfo} from '../../services/apiService';
import Footer from './components/Footer';

const Root = () => {

    const [deviceId, setDeviceId] = useState<string>("");
    const [vehicleReg, setVehicleReg] = useState<string>("");
    const [deviceInfo, setDeviceInfo] = useState<DeviceInformation>({
        active: true,
        description: "",
        duration: 0,
        interval: 0,
        operator: "",
        logo: undefined
    });
    const [validationInfo, setValidationInfo] = useState<ValidationAndVehicleInformation | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ErrorTypes | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false);

    let intervalId: number;

    useEffect(() => {
        const deviceIdFromStorage = localStorage.getItem(constants.DEVICE_ID)

        if (deviceIdFromStorage !== null) {
            setDeviceId(deviceIdFromStorage);

            getInformation()
                .then((response) => {
                    if (response.type === "DATA") {
                        setDeviceInfo(response.data);
                    } else {
                        setError('unhandled')
                    }
                })
                .catch((err) => handleError(err))
                .finally(() => setLoading(false));

            intervalId = window.setInterval(() => {
                createCheckInWithInterval()
            }, config.STATUS_CHECKIN_TIME_INTERVAL_TIME_INTERVAL_CHECK_STATUS)
        } else {
            setError("invalidDevice");
            setLoading(false)
        }
    }, []);

    const createCheckInWithInterval = () => {
        const timeInterval = config.STATUS_CHECKIN_TIME_INTERVAL;
        const sendCheckIn = () => {
            sendStatusCheckIn()
                .then(() => {
                    (error !== 'noMatch' || !success) && setError(undefined)
                    localStorage.setItem(constants.CHECKIN_SENT_DATE, String(new Date()))
                })
                .catch((err) => handleError(err))
        };

        const sentDate = localStorage.getItem(constants.CHECKIN_SENT_DATE);
        if (sentDate == null) {
            sendCheckIn();
        } else {
            const lastSentMillis = new Date(sentDate).getTime();
            const nowMillis = Date.now();
            if (nowMillis - lastSentMillis > timeInterval) {
                sendCheckIn();
            }
        }
    };

    const handleError = (err: Error) => {
        if (err.message === 'The device has been deleted') {
            intervalId && clearInterval(intervalId);
            setError("deviceDeleted");
        } else {
            setError('unhandled');
        }
    };
    const reset = () => window.location.reload();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => setVehicleReg(event.target.value.toUpperCase());

    const handleOnBtnClick = (code: string) => setVehicleReg(vehicleReg + code.toLocaleUpperCase());

    const handleOnBackspaceClick = () => setVehicleReg(vehicleReg.substr(0, vehicleReg.length - 1));

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        postValidationRequest();
    };

    const postValidationRequest = () => {
        setLoading(true);

        const request = {
            vehicle_reg: vehicleReg,
            device_id: deviceId
        };

        validateAndVehicleInfo(request).then((response) => {
            if (response.type === "DATA") {
                setValidationInfo(response.data)
                if (!response.data.validation_result?.is_validation_successful) {
                    setSuccess(false);
                    if (response.data.validation_result.validation_failure_reason === 'VALIDATION_ALREADY_EXISTS') {
                        setError('alreadyValidated');
                    } else if (response.data.validation_result.validation_failure_reason === 'NO_ACTIVE_PARKING_FOUND') {
                        setError('noMatch')
                    } else if (response.data.validation_result.validation_failure_reason === 'CONCURRENT_LIMIT_EXCEEDED') {
                        setError('concurrentLimitExceeded')
                    }
                } else if (response.data.validation_result?.is_validation_successful) {
                    setSuccess(true);
                } else {
                    setError('unhandled');
                }
            } else if (response.type === 'ERROR_MESSAGE') {
                if (response.message.includes('Network Error')) {
                    setError('networkError');
                } else {
                    setError('unhandled')
                }
            } else {
                setError('unhandled')
            }
        })
            .catch((err) => handleError(err))
            .finally(() => setLoading(false))
    };

    const renderContent = () => {
        if (loading) {
            return <Spinner size="lg" delay="none"/>
        } else if (success) {
            return <Success reset={reset} plateId={vehicleReg} duration={deviceInfo.duration} startTime={validationInfo?.validation_result?.validation_response?.start_time ?? null}
                            endTime={validationInfo?.validation_result?.validation_response?.end_time ?? null} vehicleBrand={validationInfo?.vehicle_brand} vehicleModel={validationInfo?.vehicle_model} vehicleColor={validationInfo?.vehicle_color}/>
        } else if (error) {
            return <Error type={error} reset={reset} plateId={vehicleReg} vehicleBrand={validationInfo?.vehicle_brand} vehicleModel={validationInfo?.vehicle_model} vehicleColor={validationInfo?.vehicle_color}/>
        } else {
            return (
                <>
                    <h1>{deviceInfo.description ? deviceInfo.description : Util.secondsToHms(deviceInfo.duration) + ' ' + Translation.messages().tapnpark.device.title}</h1>
                    <h3>{Translation.messages().tapnpark.device.enter_vehicle_reg}:</h3>
                    <form onSubmit={handleSubmit}>
                        <Input type="text" value={vehicleReg} onChange={handleChange} className="vehicle-reg-input"
                               disabled={true}/>
                        <Button disabled={!vehicleReg}>{Translation.messages().common.buttons.ok}</Button>
                    </form>
                    <KeyboardComponent handleOnClick={handleOnBtnClick} handleBackspace={handleOnBackspaceClick}/>
                </>
            );
        }
    };

    return (
        <>
            {renderContent()}
            <Footer deviceInfo={deviceInfo}/>
        </>
    )
};

export default Root;
